import React from "react";
import "../css/DeleteRequestSubmission.css";
import logo from '../EC.png';
import Footer from "../components/Footer";

const DeleteRequestSubmission = () => {
    const handleClose = () => {
        window.close();
    };

    return (
        <div className="accountDeletionConfirmation">
            <nav className="accountDeletionConfirmation-navbar bg-dark fixed-top reset-navbar">
                <img src={logo} height={60} alt="Website Logo" />
                <p>Account Data Deletion Request</p>
            </nav>

            <div className="message-div account-deletion-wrapper">
                <div className="text-danger text-wrapper">
                    <p className="account-main-text">
                        Your account data deletion request has been submitted successfully.
                    </p>
                    {/*<button className="close-button" onClick={handleClose}>Close Windows</button>*/}
                </div>
            </div>

            {/*<Footer/>*/}
        </div>
    );
}

export default DeleteRequestSubmission;