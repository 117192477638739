import React from "react";
import { Link } from "react-router-dom";
import "./FooterStyle.css";

const Footer = () => {
    return (
        <div className="foot">
            <div className="left-content">
                <ul className="links">
                    <li>
                        <Link to="/terms">Terms of Use</Link>
                    </li>
                    <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                </ul>
            </div>
            <div className="right-content">
                <p className="copyright-text">© 2025 Equity Rides. All Rights Reserved.</p>
            </div>
        </div>
    );
};

export default Footer;
