import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../css/AccountDeletion.css";
import logo from '../EC.png';
import Footer from "../components/Footer";
import emailjs from "emailjs-com";

const API_URL = "/equityride/delete-account-data-request";
// const authToken = localStorage.getItem("auth-token");

const AccountDeletion = () => {

    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmationStep, setConfirmationStep] = useState(false);
    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [cookies] = useCookies(["auth-token"]);
    const navigate = useNavigate();

    const handleInitialSubmit = (e) => {
        e.preventDefault();
        let validationErrors = {};



        if (!email) {
            validationErrors.email = 'Account Email is required';
        }

        if (!reason) {
            validationErrors.reason = 'Reason for deletion is required';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});
        setApiError('');
        setConfirmationStep(true);
    };

    // const handleFinalSubmit = async (e) => {
    //     e.preventDefault();
    //     setIsSubmitting(true);
    //
    //     const deletionReason = reason === "Other" ? otherReason : reason;
    //
    //     try {
    //         const response = await fetch(API_URL, {
    //             method: "POST",
    //
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Accept": "*/*",
    //
    //             },
    //             body: JSON.stringify({
    //                 email,
    //                 reason: deletionReason,
    //             }),
    //         });
    //
    //         // const response = await fetch(API_URL, {
    //         //     method: "POST",
    //         //     headers: {
    //         //         "Content-Type": "application/json",
    //         //         "Authorization": `Bearer ${authToken}`,
    //         //     },
    //         //     body: JSON.stringify({ email, reason }),
    //         // });
    //
    //         const data = await response.json();
    //         setIsSubmitting(false);
    //
    //         if (data?.result === "success") {
    //             setEmail('');
    //             setReason('');
    //             setOtherReason('');
    //             setConfirmationStep(false);
    //             navigate("/account-delete-submission");
    //         } else {
    //             // setApiError("Account data deletion request has not been placed. Please try again.");
    //         }
    //     } catch (error) {
    //         setIsSubmitting(false);
    //         // setApiError("Account data deletion request has not been placed. Please try again.");
    //     }
    //     navigate("/account-delete-submission");
    // };




    const handleFinalSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const deletionReason = reason === "Other" ? otherReason : reason;


        const emailParams = {
            email: email,
            reason: deletionReason,
        };


        emailjs.send(
            "service_qt3k50h",
            "template_oekz2in",
            emailParams,
            "egL25eK1pebKSMbZH"
        )
            .then((response) => {
                console.log("Email successfully sent!", response.status, response.text);



                setEmail('');
                setReason('');
                setOtherReason('');
                setConfirmationStep(false);


                navigate("/account-delete-submission");
            })
            .catch((error) => {
                console.error("Email sending failed!", error);
                alert("Failed to send email. Please try again.");
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };



    return (
        <div className="account-deletion-page">
            <nav className="account-deletion-navbar navbar bg-dark fixed-top reset-navbar">
                <div className="navbar-content">
                    <img src={logo} height={80} alt="Website Logo" className="navbar-logo" />
                    <p>Account Data Deletion Request</p>
                </div>
            </nav>

            <div className="account-deletion--container">
                <div className="account-deletion--warning">
                    <p>We are sorry to hear that you'd like to delete your account.</p>
                    <p>If you delete your account, then</p>
                    <ul>
                        <li>You will not be able to log into the app using this account.</li>
                        <li>You will not be able to restore this account.</li>
                        <li>You will be removed from linked services.</li>
                    </ul>
                </div>
                <div className="account-deletion--form-container">
                    <form onSubmit={confirmationStep ? handleFinalSubmit : handleInitialSubmit}>

                        <div className="account-deletion--form-group">
                            <label>Account Email:</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {errors.email && <div className="error-message">{errors.email}</div>}
                        </div>
                        <div className="account-deletion--form-group">
                            <label>Reason for Deletion:</label>
                            <div className="radio-group">
                                <div className="radio-item">
                                    <input
                                        type="radio"
                                        value="I don't find the incentives beneficial."
                                        checked={reason === "I don't find the incentives beneficial."}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    <h6>I don't find the incentives beneficial.</h6>
                                </div>
                                <div className="radio-item">
                                    <input
                                        type="radio"
                                        value="I have moved away from the DMV area."
                                        checked={reason === "I have moved away from the DMV area."}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    <h6>I have moved away from the DMV area.</h6>
                                </div>
                                <div className="radio-item">
                                    <input
                                        type="radio"
                                        value="I use other trip incentivizing apps."
                                        checked={reason === "I use other trip incentivizing apps."}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    <h6>I use other trip incentivizing apps.</h6>
                                </div>
                                <div className="radio-item">
                                    <input
                                        type="radio"
                                        value="Other"
                                        checked={reason === "Other"}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    <h6>Other</h6>
                                </div>
                                {reason === "Other" && (
                                    <textarea
                                        value={otherReason}
                                        onChange={(e) => setOtherReason(e.target.value)}
                                        required
                                        rows="4"
                                        placeholder="Please specify..."
                                    />
                                )}
                            </div>
                            {errors.reason && <div className="error-message">{errors.reason}</div>}
                        </div>
                        {confirmationStep && (
                            <div className="account-deletion--form-group">
                                <label style={{color: 'red'}}>Are you sure you want to delete your account and data?
                                    This action cannot be undone.</label>
                            </div>
                        )}
                        {apiError && <div className="error-message">{apiError}</div>}
                        <div className="account-deletion--form-group">
                            <button type="submit" disabled={isSubmitting}>
                                {confirmationStep ? (isSubmitting ? "Submitting..." : "Confirm Deletion") : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default AccountDeletion;
